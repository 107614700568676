<template>
  <div v-if="quotableLink" class="ma-0 d-flex ga-1 pa-3 align-center flex-wrap">
    <v-spacer />
    <app-button
      :to="quotableLink"
      variant="outlined"
      color="orange"
      class="text-none rounded-pill"
      data-testid="view-quotable-button"
      :text="quotableText"
      :block="smAndDown"
      :prepend-icon="mdiOpenInNew"
    />
  </div>
</template>

<script setup>
import { useImpairedRiskQuoteView } from "@/stores/impaired-risk-quote-view";
import { getRouterLink, getTypeText } from "@/util/helpers";
import { mdiOpenInNew } from "@mdi/js";
import { storeToRefs } from "pinia";
const { quotableType, quotableId } = storeToRefs(useImpairedRiskQuoteView());

let quotableLink;
let quotableText;
if (quotableType.value && quotableId.value) {
  quotableLink = getRouterLink(quotableType.value, quotableId.value);
  quotableText = `View ${getTypeText(quotableType.value)}`;
}
</script>
