<template>
  <v-row dense class="ma-1">
    <v-col cols="12">
      <h2 class="pl-1 font-weight-light">Create a new Quote</h2>
    </v-col>
    <v-col cols="12">
      <quoting-options />
    </v-col>
    <v-col cols="12" class="pt-5">
      <h2 class="pl-1 font-weight-light">View an Existing Quote</h2>
    </v-col>
    <v-col cols="12">
      <v-card class="pb-3">
        <v-row class="ma-0 pa-3" align="center">
          <v-tabs
            v-model="activeTab"
            style="width: unset"
            show-arrows
            color="primary"
          >
            <template v-for="tab in tabs" :key="tab.text">
              <v-tab v-if="tab.to" :to="tab.to" class="text-none">
                <v-icon class="inherit-color" :icon="tab.icon" />
                {{ tab.text }}
              </v-tab>
              <v-tab v-else class="text-none">
                <v-icon class="inherit-color" :icon="tab.icon" />
                {{ tab.text }}
              </v-tab>
            </template>
          </v-tabs>
          <v-row class="ma-0" justify="end" align="center">
            <table-stats
              v-if="tabs[activeTab].ref?.stats"
              :stats="tabs[activeTab].ref.stats"
            />
            <app-button
              v-if="tabs[activeTab].createRoute"
              :icon="mdiPlusCircle"
              variant="text"
              density="comfortable"
              class="table-btn"
              :to="{ name: tabs[activeTab].createRoute }"
            />
            <app-button
              v-if="tabs[activeTab].ref?.emailData"
              :icon="mdiFileExport"
              variant="text"
              density="comfortable"
              class="table-btn"
              :loading="tabs[activeTab].emailingData"
              @click="tabs[activeTab].ref.emailData()"
            />
            <app-button
              v-if="tabs[activeTab].ref?.getData"
              variant="text"
              density="comfortable"
              class="table-btn"
              :icon="mdiRefresh"
              @click="tabs[activeTab].ref.getData()"
            />
          </v-row>
        </v-row>

        <v-tabs-window v-model="activeTab" touchless>
          <template v-for="(tab, index) in tabs">
            <v-tabs-window-item v-if="tab.component" :key="tab.component">
              <component
                :is="tab.component"
                :ref="e => (tabs[index].ref = e)"
                simplified-header
              />
            </v-tabs-window-item>
          </template>
        </v-tabs-window>
      </v-card>
    </v-col>
  </v-row>
</template>

<script setup>
import QuotingOptions from "@/components/quotes/QuotingOptions.vue";
import QuotesTable from "@/components/quotes/QuotesTable.vue";
import ImpairedRiskQuotesTable from "@/components/impaired-risk-quote/ImpairedRiskQuotesTable.vue";
import TableStats from "@/components/shared/data-table/TableStats.vue";
import { useHead } from "@unhead/vue";
import {
  mdiCalculator,
  mdiRocketLaunch,
  mdiStethoscope,
  mdiPlusCircle,
  mdiFileExport,
  mdiRefresh
} from "@mdi/js";
import { ref, markRaw, watch } from "vue";
import { useRouter } from "vue-router";
useHead({ title: "Quoting" });

const router = useRouter();
const tabs = ref([
  {
    text: "Quote Requests",
    component: markRaw(QuotesTable),
    createRoute: "CreateQuote",
    ref: null,
    icon: mdiCalculator,
    page: "quote-requests"
  },
  {
    text: "Impaired Risk Quotes",
    component: markRaw(ImpairedRiskQuotesTable),
    createRoute: "ImpairedRiskQuoteCreate",
    ref: null,
    icon: mdiStethoscope,
    page: "impaired-risk-quotes"
  },
  {
    text: "Quote & Apply eApps",
    to: { name: "QuoteAndApply", query: { view: "list" } },
    icon: mdiRocketLaunch,
    link: true
  }
]);

const activeTab = ref(syncQueryToTab());

function syncQueryToTab() {
  const page = router.currentRoute.value.query.page;
  const index = tabs.value.findIndex(t => t.page === page && !t.link);
  return index === -1 ? 0 : index;
}

function syncTabToQuery() {
  const page = tabs.value[activeTab.value].page;
  if (router.currentRoute.value.query.page === page) return;
  router.replace({ query: { page } });
}

watch(activeTab, syncTabToQuery);
</script>
