<template>
  <v-row class="ma-0 pa-3 ga-1" justify="end">
    <app-button
      v-if="showCaseLink"
      variant="outlined"
      data-testid="action-view-case"
      class="text-none rounded-pill"
      color="orange"
      text="View Case"
      :prepend-icon="mdiBriefcase"
      :to="getRouterLink('Case', quoteCase)"
    />
    <app-button
      v-if="showEappLink"
      variant="outlined"
      data-testid="action-view-eapp"
      class="text-none rounded-pill"
      color="accent"
      text="View eApp"
      :prepend-icon="mdiRocketLaunch"
      :to="{ name: 'ElectronicApplicationView', params: { id: quoteEapp } }"
    />

    <related-impaired-risk-quotes-button
      :quotes="impairedRiskQuotes"
      @create="createImpairedRiskQuote"
    />
  </v-row>
</template>

<script setup>
import ImpairedRiskQuoteCreate from "@/components/impaired-risk-quote/ImpairedRiskQuoteCreate.vue";
import RelatedImpairedRiskQuotesButton from "@/components/shared/actions/RelatedImpairedRiskQuotesButton.vue";

import { getRouterLink } from "@/util/helpers";
import { storeToRefs } from "pinia";
import { useDialogStore } from "@/stores/dialog";
import { useQuoteViewStore } from "@/stores/quote-view";
import { mdiBriefcase, mdiRocketLaunch } from "@mdi/js";
import { computed, markRaw } from "vue";

const dialog = useDialogStore();
const {
  eapp: quoteEapp,
  case: quoteCase,
  id,
  impairedRiskQuotes
} = storeToRefs(useQuoteViewStore());
const showEappLink = computed(() => Boolean(quoteEapp.value));
const showCaseLink = computed(() => Boolean(quoteCase.value));

function createImpairedRiskQuote() {
  dialog.showDialog({
    component: markRaw(ImpairedRiskQuoteCreate),
    quoteId: id.value,
    scrollable: true,
    isDialog: true
  });
}
</script>
