<template>
  <v-data-table-server
    v-model:sort-by="table.options.value.sortBy"
    v-model:items-per-page="table.options.value.itemsPerPage"
    v-model:page="table.options.value.page"
    must-sort
    data-testid="impaired-risk-quotes-table"
    :mobile="null"
    mobile-breakpoint="sm"
    :headers="table.tableHeaders.value"
    :items="table.mappedItems.value"
    :items-length="table.itemsLength.value"
    :loading="table.loading.value"
    :footer-props="table.footerProps.value"
    :items-per-page-options="table.itemsPerPageOptions"
    @update:options="getData"
  >
    <template #top>
      <v-row v-if="!props.simplifiedHeader" class="ma-0 px-3" align="center">
        <h1 class="text-h5">Impaired Risk Quotes</h1>
        <v-spacer />
        <app-button
          :icon="mdiRefresh"
          variant="text"
          density="comfortable"
          class="table-btn"
          data-testid="impaired-risk-quotes-table-refresh"
          :loading="table.loading.value"
          @click="getData"
        />
        <app-button
          :icon="mdiPlusCircle"
          variant="text"
          density="comfortable"
          class="table-btn"
          data-testid="impaired-risk-quotes-table-create"
          :to="{ name: 'ImpairedRiskQuoteCreate' }"
        />
      </v-row>
      <table-filter
        :model-value="table.filter.value"
        :headers="table.filterHeaders.value"
        :loading="table.loading.value"
        @update="updateFilter"
      />
    </template>

    <template #bottom>
      <table-footer
        v-model:page="table.options.value.page"
        v-model:items-per-page="table.options.value.itemsPerPage"
        :items-per-page-options="table.itemsPerPageOptions"
        :items-length="table.itemsLength.value"
      />
    </template>

    <template #[`item.createdAt`]="{ item }">
      <timestamp-formatter :model-value="item.createdAt" />
    </template>
    <template #[`item.insuredName`]="{ item }">
      <router-link
        :to="{
          name: 'ImpairedRiskQuoteView',
          params: { id: item.additional.id }
        }"
        class="text-none"
      >
        {{ item.insuredName }}
      </router-link>
    </template>
    <template #[`item.offers`]="{ item }">
      {{ item.additional.offers }} of {{ item.additional.requests }}
    </template>
  </v-data-table-server>
</template>

<script setup>
import TimestampFormatter from "@/components/shared/formatters/TimestampFormatter.vue";

import TableFilter from "@/components/shared/data-table/TableFilter.vue";
import TableFooter from "@/components/shared/data-table/TableFooter.vue";

import { mdiRefresh, mdiPlusCircle } from "@mdi/js";
import { storeToRefs } from "pinia";
import {
  IMPAIRMENTS,
  IMPAIRED_RISK_STATUSES
} from "@/models/ImpairedRiskQuote";

import TableHeader from "@/classes/data-table/TableHeader";
import { TableOptions } from "@/classes/data-table/TableOptions";
import { parseErrorMessage } from "@/util/helpers";

import { getQuickQuotes } from "@/api/quick-quotes.service";
import { useUserStore } from "@/stores/user";
import { useSnackbarStore } from "@/stores/snackbar";
import { useTableStore } from "@/stores/table";
import { useTable } from "@/composables/table.composable";

const props = defineProps({ simplifiedHeader: Boolean });

const snackbar = useSnackbarStore();
const user = useUserStore();
const { impairedRiskQuotesTable } = storeToRefs(useTableStore());

const table = useTable({
  headers: [
    new TableHeader({
      text: "Insured Name",
      value: "insuredName",
      map: "insuredName",
      sortFilterMap: "insured",
      ...TableHeader.IS_STRING_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_QUERY_FILTER
    }),
    new TableHeader({
      text: "Advisor",
      value: "advisor",
      map: "advisorName",
      sortFilterMap: [
        { key: "advisor_id", value: "id" },
        { key: "advisor_type", value: "type" }
      ],
      ...TableHeader.IS_ADVISOR_SEARCH_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_QUERY_FILTER
    }),
    new TableHeader({
      text: "Created",
      value: "createdAt",
      map: "createdAt",
      sortFilterMap: "quick_quotes.created_at",
      ...TableHeader.IS_DATE_FILTER_TYPE,
      ...TableHeader.IS_SORTABLE,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_QUERY_FILTER
    }),
    new TableHeader({
      text: "# Offers",
      value: "offers",
      map: "offers"
    }),
    new TableHeader({
      text: "Status",
      value: "status",
      map: "status",
      selectableOptions: IMPAIRED_RISK_STATUSES,
      sortFilterMap: "status",
      ...TableHeader.IS_SELECT_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_QUERY_FILTER
    }),
    new TableHeader({
      text: "Impairment",
      value: "impairment",
      map: "impairment",
      selectableOptions: IMPAIRMENTS,
      sortFilterMap: "impairment",
      ...TableHeader.IS_SELECT_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_ADDITIONAL,
      ...TableHeader.IS_QUERY_FILTER
    })
  ],
  getData: getQuickQuotes
});

if (user.isGroupTwoPlus) {
  table.headers.value.unshift(
    new TableHeader({
      text: "View",
      value: "view",
      map: "view",
      selectableOptions: [
        { title: "All Impaired Risk Quotes", value: "all" },
        { title: "My Impaired Risk Quotes", value: "my" }
      ],
      sortFilterMap: "view",
      filterOrder: 1,
      ...TableHeader.IS_SELECT_FILTER_TYPE,
      ...TableHeader.IS_FILTERABLE,
      ...TableHeader.IS_ADDITIONAL,
      ...TableHeader.IS_MANDATORY,
      ...TableHeader.IS_QUERY_FILTER
    })
  );
}

let defaultFilter = {};
if (user.isGroupTwoPlus) {
  defaultFilter.view = user.isGroupThreePlus ? "all" : "my";
}

table.ingestFromStore({
  filter: impairedRiskQuotesTable.value.filter,
  options: impairedRiskQuotesTable.value.options,
  defaultFilter,
  defaultOptions: TableOptions({
    sortBy: [{ key: "createdAt", order: "desc" }]
  })
});

async function getData() {
  const additionalFilter = {};
  if (user.isGroupOne) additionalFilter.view = "my";

  try {
    await table.getData(additionalFilter);
  } catch (e) {
    snackbar.showErrorSnackbar({
      message: parseErrorMessage(e)
    });
  }
}

function updateFilter(filter) {
  table.filter.value = filter;
  table.resetPage();
  getData();
}

defineExpose({ getData });
</script>
