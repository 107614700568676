<template>
  <div>
    <v-divider class="pb-3" />
    <v-row v-if="checkboxes && checkboxes.length" class="px-6 pb-3">
      <checkbox-field
        v-for="checkbox in checkboxes"
        :key="checkbox.key"
        v-model="options[checkbox.key]"
        class="mr-4"
        hide-details
        :label="checkbox.label"
        :data-testid="`chat-${checkbox.dataTestid}`"
        :disabled="loading"
      />
    </v-row>
    <textarea-field
      v-if="noDocuments"
      v-model="message"
      data-testid="chat-message-input"
      class="pa-3"
      rows="2"
      auto-grow
      placeholder="Write a comment"
      hide-details="auto"
      :prepend-inner-icon="mdiText"
      :disabled="loading"
      :error-messages="messageValidation.errorMessages"
      :success="messageValidation.success"
    />
    <textarea-field
      v-else
      v-cloak
      v-model="message"
      data-testid="chat-message-input"
      :prepend-inner-icon="mdiText"
      class="pa-3"
      rows="2"
      auto-grow
      :placeholder="
        files?.length ? filesOnlyPlaceholderMessage : 'Write a comment'
      "
      :disabled="loading"
      :hide-details="!messageValidation.errorMessages.length"
      :error-messages="messageValidation.errorMessages"
      :success="messageValidation.success"
      @drop.prevent.stop="addDropFile"
      @dragover.prevent.stop
    >
      <template #append-inner>
        <div class="mx-n6 mt-3">
          <v-file-input
            v-model="compFiles"
            hide-input
            hide-details
            class="pt-0 mt-0"
            multiple
            style="max-width: 34px !important"
            :disabled="loading"
            :accept="ALL_FILE_EXTENSIONS"
            @dragover.prevent.stop
            @drop.prevent.stop
          />
        </div>
      </template>
    </textarea-field>
    <v-row class="px-6 pt-2" align="center">
      <v-chip-group inline>
        <v-chip
          v-for="(file, index) in files"
          :key="index"
          pill
          closable
          :disabled="loading"
          :text="file.name"
          :close-icon="mdiClose"
          @click:close="removeFile(index)"
        />
      </v-chip-group>
    </v-row>
    <v-row class="px-6 mb-3">
      <v-spacer />
      <template v-if="additionalButtons?.length">
        <app-button
          v-for="button in additionalButtons"
          :key="button.text"
          :data-testid="button.dataTestid"
          class="text-none"
          @click="button.method"
        >
          {{ button.text }}
        </app-button>
      </template>
      <app-button
        color="primary"
        class="text-none ml-1"
        data-testid="chat-send-message"
        :loading="loading"
        @click="submitMessage"
      >
        Comment
      </app-button>
    </v-row>
  </div>
</template>

<script setup>
import {
  ALL_FILE_EXTENSIONS,
  EXTENSION_DICTIONARY
} from "@/data/allowed-file-extensions";

import { useUserStore } from "@/stores/user";
import { mdiText, mdiClose } from "@mdi/js";
import { computed, ref } from "vue";
import useVuelidate from "@vuelidate/core";
import { computedValidation } from "@/util/helpers";

const props = defineProps({
  noDocuments: Boolean,
  checkboxes: { type: Array, required: false, default: () => [] },
  additionalButtons: { type: Array, required: false, default: () => [] },
  createFunc: { type: Function, required: true }
});

const user = useUserStore();

const options = ref(createOptions());
const files = ref([]);
const message = ref("");
const loading = ref(false);

const v$ = useVuelidate(
  {
    message: {
      required: value => {
        if (files.value.length) return true;
        return Boolean(value);
      }
    }
  },
  { message },
  { $scope: null, $autoDirty: true }
);

const compFiles = computed({
  get() {
    return files.value;
  },
  set(val) {
    addDropFile(val);
  }
});

const messageValidation = computedValidation(v$.value.message, {
  required: "Required"
});

const filesOnlyPlaceholderMessage = computed(() => {
  let attachmentText = "attachment";
  if (files.value?.length > 1) attachmentText += "s";
  return `${user.loginable.name} uploaded ${files.value?.length} ${attachmentText}.`;
});

function createOptions() {
  if (!props.checkboxes?.length) return {};

  return props.checkboxes?.reduce(
    (acc, cb) => ({ ...acc, [cb.key]: cb.default || false }),
    {}
  );
}

function removeFile(index) {
  files.value.splice(index, 1);
}

// props.createFunc handles errors
async function submitMessage() {
  const isValid = await v$.value.$validate();
  if (!isValid) return;

  loading.value = true;
  const res = await props.createFunc({
    ...options.value,
    message: message.value || filesOnlyPlaceholderMessage.value,
    files: files.value
  });

  if (res) {
    files.value.splice(0, files.value.length);
    message.value = "";
    options.value = createOptions();
    v$.value.$reset();
  }

  loading.value = false;
}

function addDropFile(e) {
  const droppedFiles = [];
  if (Array.isArray(e)) {
    droppedFiles.push(...e);
  } else {
    droppedFiles.push(...Array.from(e.dataTransfer.files));
  }

  const filteredFiles = droppedFiles.filter(v => {
    const chunks = v.name.split(".");
    return EXTENSION_DICTIONARY[chunks[chunks.length - 1].toLowerCase()];
  });

  files.value.push(...filteredFiles);
}
</script>
