import { setNoteFromRequest } from "@/models/Note";
import { setCommissionSplitFromRequest } from "@/models/CommissionSplit";

export const BEST_TIME_TO_CALL_OPTIONS = ["Morning", "Afternoon", "Evening"];

export function ElectronicApplication(model = {}) {
  return {
    allowCommissionChange: model?.allowCommissionChange || false,
    agent: model?.agent || null,
    parties: model?.parties || null,
    applyLink: model?.applyLink || null,
    approvedDomain: ApprovedDomain(model?.approvedDomain),
    birthdate: model?.birthdate || null,
    canBypassAppointmentHold: model?.canBypassAppointmentHold || false,
    canSkipPreAppointment: model?.canSkipPreAppointment || false,
    canSendChatTemplate: model?.canSendChatTemplate || false,
    carrier: Carrier(model?.carrier),
    case: model?.case || null,
    commissionsPaidTo: {
      id: model?.commissionsPaidTo?.id || null,
      name: model?.commissionsPaidTo?.name || null,
      type: model?.commissionsPaidTo?.type || null
    },
    commissionSplits: model?.commissionSplits || [],
    conversion: model?.conversion || null,
    createdAt: model?.createdAt || null,
    daysPending: model?.daysPending || null,
    editedStatus: model?.editedStatus || null,
    electronicApplicationAccesses: model?.electronicApplicationAccesses || null,
    electronicApplicationCases: model?.electronicApplicationCases || null,
    envelopeExpirationAt: model?.envelopeExpirationAt || null,
    examAddress: model?.examAddress || null,
    examDate: model?.examDate || null,
    examDateTime: model?.examDateTime || null,
    hasPhysician: model?.hasPhysician || null,
    humanApiInvitedAt: model?.humanApiInvitedAt || null,
    humanized: model?.humanized || null,
    id: model?.id || null,
    illustration: model?.illustration || null,
    illustrations: model?.illustrations || [],
    impairedRiskQuotes: model?.impairedRiskQuotes || [],
    insured: Party(model?.insured),
    jointInsured: Party(model?.jointInsured),
    isCompleted: model?.isCompleted || null,
    isDeletable: model?.isDeletable || false,
    isEditable: model?.isEditable || false,
    isResendable: model?.isResendable || false,
    isRedatable: model?.isRedatable || false,
    latestQuotes: LatestQuotes(model?.latestQuotes),
    locked: Boolean(model?.locked),
    metadata: model?.metadata || null,
    name: model?.name || null,
    namedStep: model?.namedStep || null,
    nextStep: model?.nextStep || null,
    notes: model?.notes || null,
    npn: model?.npn || null,
    orderNumber: model?.orderNumber || null,
    overrideCommissionSplits: Boolean(model?.overrideCommissionSplits),
    policyNumber: model?.policyNumber || null,
    product: EAppProduct(model?.product),
    quoteRequests: model?.quoteRequests || [],
    referredBy: model?.referredBy || null,
    resumeCarrierEapp: model?.resumeCarrierEapp || false,
    selectedType: model?.selectedType || null,
    signers: model?.signers || null,
    status: model?.status || null,
    step: model?.step || null,
    syncNow: model?.syncNow || null,
    test: model?.test || false,
    tia: model?.tia || null,
    followUpAt: model?.followUpAt || null,
    followUpIsScheduled: model?.followUpIsScheduled || null
  };
}

export function setElectronicApplicationFromRequest(raw = {}) {
  const eApp = ElectronicApplication();
  eApp.allowCommissionChange = Boolean(raw?.allow_commission_change);
  eApp.agent = raw?.agent;
  eApp.applyLink = raw?.apply_link;
  eApp.canBypassAppointmentHold = Boolean(raw?.can_skip_hold_for_appointment);
  eApp.canSkipPreAppointment = Boolean(raw?.can_skip_preappointment);

  eApp.case = raw?.case;
  eApp.createdAt = raw?.created_at;
  if (raw?.commissions_paid_to) {
    eApp.commissionsPaidTo.id = raw.commissions_paid_to.id;
    eApp.commissionsPaidTo.name = raw.commissions_paid_to.name;
    eApp.commissionsPaidTo.type = raw.commissions_paid_to.type;
  }
  eApp.conversion = Boolean(raw?.conversion);
  eApp.daysPending = raw?.days_pending;
  eApp.editedStatus = raw?.edited_status;
  eApp.envelopeExpirationAt = raw?.envelope_expiration_at;
  eApp.examAddress = raw?.exam_address;
  eApp.examDate = raw?.exam_date;
  eApp.examDateTime = raw?.exam_date_time;
  eApp.hasPhysician = raw?.has_physician;
  eApp.humanApiInvitedAt = raw?.human_api_invited_at;
  eApp.humanized = raw?.humanized;
  eApp.id = raw?.id;
  eApp.illustration = raw?.illustration;
  eApp.illustrations = raw?.illustrations;
  eApp.isCompleted = raw?.is_completed;
  eApp.isDeletable = raw?.deletable;
  eApp.isEditable = raw?.editable;
  eApp.isRedatable = raw?.redatable;
  eApp.isResendable = raw?.resendable;
  eApp.canSendChatTemplate = raw?.can_send_chat_template;
  eApp.loanType = raw?.loan_type;
  eApp.metadata = raw?.metadata;
  eApp.name = raw?.name;
  eApp.namedStep = raw?.named_step;
  eApp.nextStep = raw?.next_step;
  eApp.npn = raw?.agents_npn;
  eApp.locked = Boolean(raw?.locked);
  eApp.orderNumber = raw?.order_number;
  eApp.policyNumber = raw?.policy_number;
  eApp.reduceSpecifiedAmount = raw?.reduce_specified_amount;
  eApp.referredBy = raw?.referred_by;
  eApp.resumeCarrierEapp = raw?.resume_carrier_eapp;
  eApp.selectedType = raw?.selected_type;
  eApp.status = raw?.status;
  eApp.step = raw?.step;
  eApp.syncNow = raw?.sync_now;
  eApp.test = raw?.test;
  eApp.tia = raw?.tia;
  eApp.followUpAt = raw?.follow_up_at;
  eApp.followUpIsScheduled = raw?.follow_up_at_is_appointment;
  eApp.overrideCommissionSplits = raw?.override_commission_splits;

  eApp.approvedDomain = setApprovedDomainFromRequest(raw?.approved_domain);

  eApp.carrier = setCarrierFromRequest(raw?.product?.carrier);

  eApp.product = setEappProductFromRequest(raw?.product);

  eApp.latestQuotes = setLatestQuotesFromRequest(raw?.latest_quotes);

  eApp.electronicApplicationCases = [];
  raw?.electronic_application_cases?.forEach(rawCase => {
    eApp.electronicApplicationCases.push(
      setElectronicApplicationExistingInsuranceFromRequest(rawCase)
    );
  });

  eApp.notes = [];
  raw?.notes?.forEach(rawNote => {
    eApp.notes.push(setNoteFromRequest(rawNote));
  });

  eApp.signers = [];
  raw?.signers?.forEach(rawSigner => {
    eApp.signers.push(
      setElectronicApplicationSignerFromRequest(rawSigner, eApp.status)
    );
  });

  eApp.electronicApplicationAccesses = [];
  raw?.electronic_application_accesses?.forEach(rawAccess => {
    eApp.electronicApplicationAccesses.push(
      setElectronicApplicationAccessFromRequest(rawAccess)
    );
  });

  eApp.impairedRiskQuotes = [];
  if (Array.isArray(raw?.quick_quotes)) {
    eApp.impairedRiskQuotes = raw?.quick_quotes?.map(
      setRelatedQuoteFromRequest
    );
  }

  eApp.quoteRequests = [];
  if (Array.isArray(raw?.quote_requests)) {
    eApp.quoteRequests = raw?.quote_requests?.map(setRelatedQuoteFromRequest);
  }

  eApp.commissionSplits =
    raw?.commission_splits?.map(setCommissionSplitFromRequest) || [];

  eApp.parties = [];
  raw?.parties?.forEach(rawParty => {
    eApp.parties.push(setPartyFromRequest(rawParty));
  });

  const insured = eApp.parties.find(({ roles }) =>
    roles.some(({ role }) => role === "Insured")
  );
  if (insured) eApp.insured = insured;

  const jointInsured = eApp.parties.find(({ roles }) =>
    roles.some(({ role }) => role === "Joint Insured")
  );

  if (jointInsured) eApp.jointInsured = jointInsured;

  return eApp;
}

function RelatedQuote(model = {}) {
  return {
    id: model?.id,
    status: model?.status,
    createdAt: model?.createdAt
  };
}

function setRelatedQuoteFromRequest(raw = {}) {
  const model = RelatedQuote();
  model.id = raw?.id;
  model.status = raw?.status;
  model.createdAt = raw?.created_at;
  return model;
}

function Party(model = {}) {
  return {
    id: model?.id || null,
    type: model?.type || null,
    medicalSources: model?.medicalSources || [],
    name: model?.name || "",
    birthdate: model?.birthdate || "",
    phone: model?.phone || "",
    email: model?.email || "",
    roles: model?.roles || []
  };
}

function setPartyFromRequest(rawModel = {}) {
  const party = Party();
  party.id = rawModel?.id;
  party.medicalSources = rawModel?.medical_sources;
  party.name = rawModel?.name;
  party.birthdate = rawModel?.date;
  party.type = rawModel?.type;
  party.phone = rawModel?.primary_phone;
  party.email = rawModel?.email;

  party.roles = [];
  rawModel?.roles?.forEach(rawRole => {
    party.roles.push({
      beneficiaryAmount: rawRole?.beneficiary_amount,
      beneficiaryQualifier: rawRole?.beneficiary_qualifier,
      role: rawRole?.role,
      relationship: rawRole?.relationship
    });
  });
  return party;
}

function EAppProduct(model = {}) {
  return {
    carrierEDelivery: model?.carrierEDelivery || false,
    chronicIllnessRider: model?.chronicIllnessRider || false,
    ltcRider: model?.ltcRider || null,
    name: model?.name || null,
    id: model?.id || null,
    multiplePolicies: model?.multiplePolicies || null
  };
}

function setEappProductFromRequest(raw = {}) {
  const product = EAppProduct();
  product.carrierEDelivery = raw?.carrier_e_delivery;
  product.chronicIllnessRider = raw?.chronic_illness_rider;
  product.ltcRider = raw?.ltc_rider;
  product.name = raw?.name;
  product.id = raw?.id;
  product.multiplePolicies = raw?.multiple_policies;
  return product;
}

function LatestQuotes(model = {}) {
  return {
    insured: QuoteParams(model?.insured),
    jointInsured: QuoteParams(model?.jointInsured)
  };
}

function setLatestQuotesFromRequest(raw = {}) {
  const latestQuotes = LatestQuotes();
  latestQuotes.insured = setQuoteParamsFromRequest(raw?.insured);
  latestQuotes.jointInsured = setQuoteParamsFromRequest(raw?.joint_insured);
  return latestQuotes;
}

function Carrier(model = {}) {
  return {
    avatarUrl: model?.avatarUrl,
    id: model?.id,
    instantAps: model?.instantAps,
    name: model?.name
  };
}
function setCarrierFromRequest(raw = {}) {
  const carrier = Carrier();
  carrier.avatarUrl = raw?.avatar_url;
  carrier.id = raw?.id;
  carrier.instantAps = raw?.instant_aps;
  carrier.name = raw?.name;
  return carrier;
}

function ApprovedDomain(model = {}) {
  return {
    domain: model?.domain,
    id: model?.id,
    isArchived: model?.isArchived,
    appointmentAssignmentOwnable: {
      id: model?.appointmentAssignmentOwnable?.id || null,
      name: model?.appointmentAssignmentOwnable?.name || null,
      type: model?.appointmentAssignmentOwnable?.type || null
    }
  };
}

function setApprovedDomainFromRequest(rawModel = {}) {
  const approvedDomain = ApprovedDomain();
  approvedDomain.domain = rawModel?.domain;
  approvedDomain.id = rawModel?.id;
  approvedDomain.isArchived = Boolean(rawModel?.archived_at);

  if (rawModel?.appointment_assignment_ownable) {
    approvedDomain.appointmentAssignmentOwnable.id =
      rawModel.appointment_assignment_ownable.id;
    approvedDomain.appointmentAssignmentOwnable.name =
      rawModel.appointment_assignment_ownable.name;
    approvedDomain.appointmentAssignmentOwnable.type =
      rawModel.appointment_assignment_ownable.type;
  }
  return approvedDomain;
}

function Section1035ExchangeParams(model = {}) {
  return {
    amount: model?.amount || null,
    enabled: model?.enabled || false
  };
}

function setSection1035ExchangeFromRequest(params = {}) {
  const section = Section1035ExchangeParams();
  section.amount = params?.amount;
  section.enabled = params?.enabled;
  return section;
}

function VitalityParams(model = {}) {
  return {
    level: model?.level || "",
    enabled: model?.enabled || false
  };
}

function setVitalityFromRequest(params = {}) {
  const vitality = VitalityParams();
  vitality.level = params?.level;
  vitality.enabled = params?.enabled;
  return vitality;
}

function QuoteParams(model = {}) {
  return {
    ageValue: model?.ageValue || null,
    benefitPeriod: model?.benefitPeriod || null,
    buildText: model?.buildText || null,
    cashBenefitPercentage: model?.cashBenefitPercentage || null,
    cashValue: model?.cashValue || null,
    chronicIllnessRider: model?.chronicIllnessRider || null,
    createdAt: model?.createdAt || null,
    creditingRate: model?.creditingRate || null,
    eliminationPeriod: model?.eliminationPeriod || null,
    faceAmount: model?.faceAmount || null,
    filterChronicIllnessAndLtc: model?.filterChronicIllnessAndLtc || null,
    gender: model?.gender || null,
    health: model?.health || null,
    height: model?.height || null,
    heightText: model?.heightText || null,
    homeHealthCareWaiver: model?.homeHealthCareWaiver || null,
    id: model?.id || null,
    incomeAmount: model?.incomeAmount || null,
    incomeEndAge: model?.incomeEndAge || null,
    incomeSolve: model?.incomeSolve || null,
    incomeStartAge: model?.incomeStartAge || null,
    inflationPercentage: model?.inflationPercentage || null,
    jointWaiverOfPremium: model?.jointWaiverOfPremium || null,
    lapseProtectionToAge: model?.lapseProtectionToAge || null,
    loanType: model?.loanType || null,
    ltcRider: model?.ltcRider || null,
    ltcRiderPercentage: model?.ltcRiderPercentage || null,
    mode: model?.mode || null,
    monthlyBenefit: model?.monthlyBenefit || null,
    partnerDiscount: model?.partnerDiscount || null,
    payDuration: model?.payDuration || null,
    poolOfMoney: model?.poolOfMoney || null,
    premium: model?.premium || null,
    product: model?.product || null,
    productCategory: model?.productCategory || null,
    rateClass: model?.rateClass || null,
    section1035Exchange: Section1035ExchangeParams(model?.section1035Exchange),
    sharedCare: model?.sharedCare || null,
    smoker: model?.smoker || null,
    solve: model?.solve || null,
    state: model?.state || null,
    targetPremium: model?.targetPremium || null,
    updatedAt: model?.updatedAt || null,
    usages: model?.usages || [],
    validatedRating: model?.validatedRating || null,
    vitality: VitalityParams(model?.vitality),
    weight: model?.weight || null,
    yearsDeferred: model?.yearsDeferred
  };
}

function setQuoteParamsFromRequest(raw = {}) {
  const quoteParams = QuoteParams();
  quoteParams.ageValue = raw?.age_value;
  quoteParams.benefitPeriod = raw?.benefit_period;
  quoteParams.buildText = raw?.build_text;
  quoteParams.cashBenefitPercentage = raw?.cash_benefit_percentage;
  quoteParams.cashValue = raw?.cash_value;
  quoteParams.chronicIllnessRider = raw?.chronic_illness_rider;
  quoteParams.createdAt = raw?.created_at;
  quoteParams.creditingRate = raw?.crediting_rate;
  quoteParams.eliminationPeriod = raw?.elimination_period;
  quoteParams.faceAmount = raw?.face_amount;
  quoteParams.filterChronicIllnessAndLtc = raw?.filter_chronic_illness_and_ltc;
  quoteParams.gender = raw?.gender;
  quoteParams.health = raw?.health;
  quoteParams.height = raw?.height;
  quoteParams.heightText = raw?.height_text;
  quoteParams.homeHealthCareWaiver = raw?.home_health_care_waiver;
  quoteParams.id = raw?.id;
  quoteParams.incomeAmount = raw?.income_amount;
  quoteParams.incomeEndAge = raw?.income_end_age;
  quoteParams.incomeSolve = raw?.income_solve;
  quoteParams.incomeStartAge = raw?.income_start_age;
  quoteParams.inflationPercentage = raw?.inflation_percentage;

  quoteParams.jointWaiverOfPremium = raw?.joint_waiver_of_premium;
  quoteParams.lapseProtectionToAge = raw?.lapse_protection_to_age;
  quoteParams.loanType = raw?.loan_type;
  quoteParams.ltcRider = raw?.ltc_rider;
  quoteParams.ltcRiderPercentage = raw?.ltc_rider_percentage;
  quoteParams.mode = raw?.mode;
  quoteParams.monthlyBenefit = raw?.monthly_benefit;
  quoteParams.partnerDiscount = raw?.partner_discount;
  quoteParams.payDuration = raw?.pay_duration;
  quoteParams.poolOfMoney = raw?.pool_of_money;
  quoteParams.premium = raw?.premium;
  quoteParams.product = raw?.product;
  quoteParams.productCategory = raw?.product_category;
  quoteParams.rateClass = raw?.rate_class;

  quoteParams.section1035Exchange = setSection1035ExchangeFromRequest(
    raw?.section_1035_exchange
  );

  quoteParams.sharedCare = raw?.shared_care;
  quoteParams.smoker = raw?.smoker;
  quoteParams.solve = raw?.solve;
  quoteParams.state = raw?.state;
  quoteParams.targetPremium = raw?.target_premium;
  quoteParams.updatedAt = raw?.updated_at;
  quoteParams.validatedRating = raw?.validated_rating;

  quoteParams.vitality = setVitalityFromRequest(raw?.vitality);
  quoteParams.weight = raw?.weight;
  quoteParams.yearsDeferred = raw?.years_deferred;

  quoteParams.usages = [];
  raw?.usages?.forEach(rawStatus => {
    const smokingStatus = {
      category: rawStatus?.category,
      frequency: rawStatus?.frequency,
      lastUseDate: rawStatus?.last_use_date
    };
    quoteParams.usages.push(smokingStatus);
  });

  return quoteParams;
}

function ElectronicApplicationAccess(model = {}) {
  return {
    additionalCaseAccess: model?.additionalCaseAccess || null,
    disabled: model?.disabled || null,
    email: model?.email || null,
    id: model?.id || null,
    owner: model?.owner || null,
    name: model?.name || "",
    ownerId: model?.ownerId || null,
    ownerType: model?.ownerType || null,

    link: model?.link || null,
    routerLink: model?.routerLink || null
  };
}

export function setElectronicApplicationAccessFromRequest(model = {}) {
  const access = ElectronicApplicationAccess();
  access.additionalCaseAccess = model?.additional_case_access;
  access.disabled = model?.disabled;
  access.email = model?.email;
  access.id = model?.id;
  access.owner = model?.owner;
  access.ownerId = model?.ownable?.id;
  access.name = model?.ownable?.name;
  access.ownerType = model?.ownable?.type?.model;

  if (access.ownerType === "Agent") {
    access.routerLink = {
      name: "AgentView",
      params: { id: access.ownerId }
    };
  } else if (access.ownerType === "Agency") {
    access.routerLink = {
      name: "AgencyView",
      params: { id: access.ownerId }
    };
  }

  return access;
}

function ElectronicApplicationSigner(model = {}) {
  return {
    signerId: model?.signerId,
    role: model?.role,
    accessCode: model?.accessCode,
    status: model?.status,
    signedAt: model?.signedAt,
    ownerEmail: model?.ownerEmail,
    ownerId: model?.ownerId,
    ownerType: model?.ownerType,
    ownerName: model?.ownerName,
    link: model?.link,
    routerLink: model?.routerLink,
    canResendEnvelope: model?.canResendEnvelope
  };
}

function setElectronicApplicationSignerFromRequest(
  model = {},
  currentEAppStatus
) {
  const signer = ElectronicApplicationSigner();
  signer.ownerName = model.ownable.name;
  signer.ownerEmail = model.ownable.email;
  signer.ownerId = model.ownable.id;
  signer.signerId = model.id;

  signer.role = model.role_name;
  signer.accessCode = model.last_4_type;
  signer.status = model.status;
  signer.statusCode = model.status_code;
  signer.signedAt = model.signed_at;

  if (model.ownable.link === "agents") {
    signer.ownerType = "Agent";
  } else if (model.ownable.link === "agencies") {
    signer.ownerType = "Agency";
  }

  if (signer.ownerType === "Agent") {
    signer.routerLink = {
      name: "AgentView",
      params: {
        id: signer.ownerId
      }
    };
  } else if (signer.ownerType === "Agency") {
    signer.routerLink = {
      name: "AgencyView",
      params: {
        id: signer.ownerId
      }
    };
  }

  signer.canResendEnvelope =
    !signer.signedAt &&
    [
      "Sent",
      "Delivered",
      "AutoResponded",
      "FaxPending",
      "AuthenticationFailed"
    ].includes(signer.statusCode) &&
    !["Quote", "Lead"].includes(currentEAppStatus);

  return signer;
}

function ElectronicApplicationExistingInsurance(model = {}) {
  return {
    carrierName: model?.carrierName,
    policyNumber: model?.policyNumber,
    status: model?.status,
    faceAmount: model?.faceAmount,
    policyDate: model?.policyDate,
    replacementReason: model?.replacementReason,
    businessInsurance: model?.businessInsurance
  };
}

function setElectronicApplicationExistingInsuranceFromRequest(model = {}) {
  const existingInsurance = ElectronicApplicationExistingInsurance();
  existingInsurance.carrierName = model.product?.carrier?.name;
  existingInsurance.policyNumber = model.policy_number;
  existingInsurance.status = model.status;
  existingInsurance.faceAmount = model.face_amount;
  existingInsurance.policyDate = model.policy_date;
  existingInsurance.replacementReason = model.replacement_reason;
  existingInsurance.businessInsurance = model.business_insurance;
  return existingInsurance;
}

function ElectronicApplicationQuote(model = {}) {
  return {
    id: model?.id,
    canDelete: model?.canDelete,
    createdAt: model?.createdAt,
    faceAmount: model?.faceAmount,
    premium: model?.premium,
    targetPremium: model?.targetPremium,
    mode: model?.mode,
    payDuration: model?.payDuration,
    solve: model?.solve,
    state: model?.state,
    firstName: model?.firstName,
    lastName: model?.lastName,
    gender: model?.gender,
    birthdate: model?.birthdate,
    health: model?.health,
    smoker: model?.smoker,
    height: model?.height,
    weight: model?.weight,
    validatedRating: model?.validatedRating,
    productCategory: model?.productCategory,
    cashValue: model?.cashValue,
    ageValue: model?.ageValue,
    creditingRate: model?.creditingRate,
    ltcRider: model?.ltcRider,
    ltcRiderPercentage: model?.ltcRiderPercentage,
    incomeAmount: model?.incomeAmount,
    incomeSolve: model?.incomeSolve,
    incomeStartAge: model?.incomeStartAge,
    incomeEndAge: model?.incomeEndAge,
    lapseProtectionToAge: model?.lapseProtectionToAge,
    filterChronicIllnessAndLtc: model?.filterChronicIllnessAndLtc,
    livingBenefits: model?.livingBenefits,
    chronicIllnessRider: model?.chronicIllnessRider,
    loanType: model?.loanType,
    section1035Exchange: model?.section1035Exchange,
    section1035ExchangeRaw: model?.section1035ExchangeRaw,
    vitality: model?.vitality,
    vitalityRaw: model?.vitalityRaw,
    mec: model?.mec,
    discount: model?.discount,
    returnOfPremiumRider: model?.returnOfPremiumRider,
    saveAge: model?.saveAge,
    guaranteedIssue: model?.guaranteedIssue,
    monthlyBenefit: model?.monthlyBenefit,
    poolOfMoney: model?.poolOfMoney,
    eliminationPeriod: model?.eliminationPeriod,
    inflationPercentage: model?.inflationPercentage,
    jointWaiverOfPremium: model?.jointWaiverOfPremium,
    homeHealthCareWaiver: model?.homeHealthCareWaiver,
    sharedCare: model?.sharedCare,
    partnerDiscount: model?.partnerDiscount,
    insuredId: model?.insuredId,
    benefitPeriod: model?.benefitPeriod,
    yearsDeferred: model?.yearsDeferred,
    carrier: {
      id: model?.carrier?.id,
      name: model?.carrier?.name,
      avatarUrl: model?.carrier?.avatarUrl,
      instantAps: model?.carrier?.instantAps
    },
    product: {
      id: model?.product?.id,
      name: model?.product?.name,
      category: model?.product?.category,
      carrierEDelivery: model?.product?.carrierEDelivery,
      ltcRider: model?.product?.ltcRider,
      chronicIllnessRider: model?.product?.chronicIllnessRider,
      advisorUseOnly: model?.product?.advisorUseOnly,
      multiplePolicies: model?.product?.multiplePolicies
    },
    illustration: {
      id: model?.illustration?.id,
      uid: model?.illustration?.uid,
      name: model?.illustration?.name,
      date: model?.illustration?.date,
      category: model?.illustration?.category,
      contentSize: model?.illustration?.contentSize,
      mimeType: model?.illustration?.mimeType
    },
    smokingUsages: model?.smokingUsages || []
  };
}

export function setElectronicApplicationQuoteFromRequest(raw) {
  const quote = ElectronicApplicationQuote();
  quote.canDelete = raw?.can_delete;
  quote.createdAt = raw?.created_at;
  quote.id = raw?.id;
  quote.faceAmount = raw?.face_amount || 0;
  quote.premium = raw?.premium;
  quote.targetPremium = raw?.target_premium;
  quote.mode = raw?.mode;
  quote.payDuration = raw?.pay_duration;
  quote.solve = raw?.solve;
  quote.state = raw?.state;
  quote.firstName = raw?.first_name;
  quote.lastName = raw?.last_name;
  quote.gender = raw?.gender;
  quote.birthdate = raw?.birthdate;
  quote.health = raw?.health;
  quote.smoker = raw?.smoker;
  quote.height = raw?.height;
  quote.weight = raw?.weight;
  quote.validatedRating = raw?.validated_rating;
  quote.productCategory = raw?.product_category;
  quote.cashValue = raw?.cash_value;
  quote.ageValue = raw?.age_value;
  quote.creditingRate = raw?.crediting_rate;
  quote.ltcRider = raw?.ltc_rider;
  quote.ltcRiderPercentage = raw?.ltc_rider_percentage;
  quote.incomeAmount = raw?.income_amount;
  quote.incomeSolve = raw?.income_solve;
  quote.incomeStartAge = raw?.income_start_age;
  quote.incomeEndAge = raw?.income_end_age;
  quote.lapseProtectionToAge = raw?.lapse_protection_to_age;
  quote.filterChronicIllnessAndLtc = raw?.filter_chronic_illness_and_ltc;
  quote.livingBenefits = raw?.living_benefits;
  quote.chronicIllnessRider = raw?.chronic_illness_rider;
  quote.loanType = raw?.loan_type;
  quote.section1035Exchange = raw?.section_1035_exchange?.amount;
  quote.section1035ExchangeRaw = raw?.section_1035_exchange;
  quote.vitality = raw?.vitality?.level;
  quote.vitalityRaw = raw?.vitality;
  quote.mec = raw?.mec;
  quote.discount = raw?.discount;
  quote.returnOfPremiumRider = raw?.return_of_premium_rider;
  quote.saveAge = raw?.save_age;
  quote.guaranteedIssue = raw?.guaranteed_issue;
  quote.monthlyBenefit = raw?.monthly_benefit;
  quote.poolOfMoney = raw?.pool_of_money || 0;
  quote.eliminationPeriod = raw?.elimination_period;
  quote.inflationPercentage = raw?.inflation_percentage;
  quote.jointWaiverOfPremium = raw?.joint_waiver_of_premium;
  quote.homeHealthCareWaiver = raw?.home_health_care_waiver;
  quote.sharedCare = raw?.shared_care;
  quote.partnerDiscount = raw?.partner_discount;
  quote.insuredId = raw?.insured_id;
  quote.benefitPeriod = raw?.benefit_period;
  quote.yearsDeferred = raw?.years_deferred;

  quote.product = {
    id: raw?.product?.id,
    name: raw?.product?.name,
    category: raw?.product?.category,
    carrierEDelivery: raw?.product?.carrier_e_delivery,
    ltcRider: raw?.product?.ltc_rider,
    chronicIllnessRider: raw?.product?.chronic_illness_rider,
    advisorUseOnly: raw?.product?.advisor_use_only,
    multiplePolicies: raw?.product?.multiple_policies
  };

  quote.carrier = {
    id: raw?.product?.carrier?.id,
    name: raw?.product?.carrier?.name,
    avatarUrl: raw?.product?.carrier?.avatar_url,
    instantAps: raw?.product?.carrier?.instant_aps
  };

  quote.illustration = {
    id: raw?.illustration?.id,
    uid: raw?.illustration?.uid,
    name: raw?.illustration?.name,
    date: raw?.illustration?.date,
    category: raw?.illustration?.category,
    contentSize: raw?.illustration?.content_size,
    mimeType: raw?.illustration?.mime_type
  };

  quote.smokingUsages = [];
  if (Array.isArray(raw.usages)) {
    raw.usages.forEach(usage => {
      quote.smokingUsages.push({
        status: usage.status,
        category: usage.category,
        frequency: usage.frequency,
        lastUseDate: usage.last_use_date,
        raw: {
          status: usage.status,
          category: usage.category,
          frequency: usage.frequency,
          last_use_date: usage.last_use_date
        }
      });
    });
  }

  return quote;
}

export function ElectronicApplicationTableItem(model = {}) {
  return {
    agentName: model?.agentName || "",
    agentAvatar: model?.agentAvatar || "",
    carrierName: model?.carrierName || "",
    created: model?.created || "",
    updatedAt: model?.updatedAt || "",
    completedAt: model?.completedAt || "",
    followUpAt: model?.followUpAt || null,
    followUpIsScheduled: model?.followUpIsScheduled || null,
    eAppId: model?.eAppId || "",
    insuredName: model?.insuredName || "",
    insuredId: model?.insuredId || 0,
    initialDeathBenefit: model?.initialDeathBenefit || 0,
    insuredPhoneMobile: model?.insuredPhoneMobile || "",
    leadStatus: model?.leadStatus || null,
    marketingManager: model?.marketingManager || "",
    mode: model?.mode || 1,
    payDuration: model?.payDuration,
    premium: model?.premium || 0,
    productName: model?.productName || "",
    status: model?.status || "",
    website: model?.website || "",
    refer: Boolean(model?.refer),
    bestTimeToCall: model?.bestTimeToCall || "",
    stepDisplayName: model?.stepDisplayName || ""
  };
}

export function setElectronicApplicationTableItemFromRequest(model = {}) {
  const item = ElectronicApplicationTableItem();

  item.agentName = model?.agent?.name;
  item.agentAvatar = model?.agent?.avatar_url;
  item.carrierName = model?.product?.carrier?.name;
  item.created = model?.created_at;
  item.updatedAt = model?.updated_at;
  item.completedAt = model?.completed_at;
  item.eAppId = model?.id;
  item.followUpAt = model?.follow_up_at;
  item.followUpIsScheduled = model?.follow_up_at_is_appointment;
  item.insuredName = model?.insured?.name;
  item.initialDeathBenefit = model?.initial_death_benefit;
  item.insuredId = model?.insured?.id;
  item.insuredPhoneMobile = model?.insured?.phone_mobile;
  item.leadStatus = model?.lead_status;
  item.marketingManager = model?.approved_domain?.marketing_manager_name;
  item.premium = model?.premium;
  item.productName = model?.product?.name;
  item.payDuration = model?.pay_duration;
  item.status = model?.edited_status;
  item.website = model?.approved_domain?.domain;
  item.mode = model?.mode;
  item.refer = model?.refer;
  item.bestTimeToCall = model?.best_time_to_call;
  item.stepDisplayName = model?.step_display_name;

  return item;
}

export function ElectronicApplicationTableStats(model = {}) {
  return {
    averagePremium: model?.averagePremium || 0,
    completion: model?.completion || 0,
    conversion: model?.conversion || 0
  };
}

export function setElectronicApplicationTableStatsFromRequest(model = {}) {
  const stats = ElectronicApplicationTableStats();
  stats.averagePremium = model?.average_premium;
  stats.completion = model?.completion;
  stats.conversion = model?.conversion;
  return stats;
}
