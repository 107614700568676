<template>
  <div class="ma-0 d-flex ga-1 pa-3 align-center flex-wrap">
    <div
      v-if="primaryContact || marketingManager.schedulingLink"
      data-testid="case-card-contact"
    >
      <strong> Need Help? </strong>
      <template v-if="primaryContact">
        Call {{ primaryContact.name }}
        <a
          :href="`tel:${primaryContact.phone}`"
          data-outbound-type="Agent"
          :data-outbound-id="primaryContact.id"
          :data-outbound-number="primaryContact.phone"
        >
          {{ primaryContact.phone }}</a
        >
      </template>
      <template v-if="marketingManager.schedulingLink">
        <template v-if="primaryContact"> or you can </template>
        <a :href="marketingManager.schedulingLink" target="_blank">
          Schedule a meeting with
          {{ marketingManager.name }}
        </a>
      </template>
    </div>
    <v-spacer />
    <div class="ma-0 d-flex ga-1 align-center flex-wrap">
      <app-button
        v-if="eappLink"
        :to="eappLink"
        variant="outlined"
        color="orange"
        class="text-none rounded-pill"
        data-testid="view-eapp-button"
        text="View eApp"
        :block="smAndDown"
        :prepend-icon="mdiOpenInNew"
      />
      <app-button
        variant="outlined"
        color="primary"
        class="text-none rounded-pill"
        data-testid="snapshot-button"
        text="Snapshot"
        :block="smAndDown"
        :href="snapshotHref"
        :prepend-icon="mdiCamera"
      />
      <app-button
        v-if="user.isGroupThreePlus"
        variant="outlined"
        color="accent"
        class="text-none rounded-pill"
        data-testid="paperclip-button"
        text="Paperclip"
        :block="smAndDown"
        :href="paperclipHref"
        :prepend-icon="mdiPaperclip"
      />

      <related-quotes-button
        :block="smAndDown"
        :quotes="quotes"
        @create="requestQuote"
      />

      <related-impaired-risk-quotes-button
        :block="smAndDown"
        :quotes="impairedRiskQuotes"
        @create="createImpairedRiskQuote"
      />

      <app-button
        v-if="convertible"
        variant="outlined"
        color="primary"
        class="text-none rounded-pill"
        data-testid="convert-button"
        text="Convert"
        :block="smAndDown"
        :prepend-icon="mdiAccountConvert"
        @click="convertToEapp"
      />
    </div>
  </div>
</template>

<script setup>
import RelatedQuotesButton from "@/components/shared/actions/RelatedQuotesButton.vue";
import RelatedImpairedRiskQuotesButton from "@/components/shared/actions/RelatedImpairedRiskQuotesButton.vue";

import SynthesizeQuoteRequestDialog from "@/dialogs/SynthesizeQuoteRequestDialog.vue";
import ImpairedRiskQuoteCreate from "@/components/impaired-risk-quote/ImpairedRiskQuoteCreate.vue";
import { getPaperclipHref, getSnapshotHref } from "@/api/cases.service";
import { storeToRefs } from "pinia";
import { useUserStore } from "@/stores/user";
import { useDialogStore } from "@/stores/dialog";
import { useCaseViewStore } from "@/stores/case-view";
import { computed, markRaw } from "vue";
import {
  mdiOpenInNew,
  mdiCamera,
  mdiPaperclip,
  mdiAccountConvert
} from "@mdi/js";
import ConfirmationDialog from "@/dialogs/ConfirmationDialog.vue";
import { getRouterLink } from "@/util/helpers";
import { useRouter } from "vue-router";
import { useDisplay } from "vuetify";

const user = useUserStore();
const caseView = useCaseViewStore();
const {
  id,
  electronicApplication,
  convertible,
  marketingManager,
  primaryContact,
  quotes,
  impairedRiskQuotes
} = storeToRefs(caseView);
const { smAndDown } = useDisplay();
const dialog = useDialogStore();
const router = useRouter();

const eappLink = computed(() => {
  if (!electronicApplication.value?.id) {
    return null;
  }
  return {
    name: "ElectronicApplicationView",
    params: {
      id: electronicApplication.value.id
    },
    query: {
      "from-case": true
    }
  };
});
const snapshotHref = computed(() => getSnapshotHref(id.value));
const paperclipHref = computed(() => getPaperclipHref(id.value));

function requestQuote() {
  dialog.showDialog({
    component: markRaw(SynthesizeQuoteRequestDialog),
    id: id.value,
    idBodyKey: "case_id",
    scrollable: true
  });
}

function convertToEapp() {
  dialog.showDialog({
    component: markRaw(ConfirmationDialog),
    title: "Would you like to start a conversion eApp?",
    subtitle: "Please confirm your intent.",
    func: async () => {
      const eapp = await caseView.convertToEapp();
      router.push(getRouterLink("ElectronicApplication", eapp.id));
    }
  });
}

function createImpairedRiskQuote() {
  dialog.showDialog({
    component: markRaw(ImpairedRiskQuoteCreate),
    caseId: id.value,
    scrollable: true,
    isDialog: true
  });
}
</script>
