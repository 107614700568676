<template>
  <v-card class="mb-12 ma-3">
    <v-card-title class="text-h5"> Create an Impaired Risk Quote </v-card-title>
    <v-divider />
    <v-card-text>
      <!-- Agent and Insured -->
      <h2 class="text-h6 mb-3">Agent and Insured</h2>
      <v-row dense>
        <v-col cols="12">
          <agent-search
            v-model="quote.advisor"
            label="Agent"
            data-testid="agent"
            :disabled="loadingQuoteData"
            :success="advisorValidation.success"
            :error-messages="advisorValidation.errorMessages"
          />
        </v-col>
        <v-col cols="12" :md="isDialog ? 6 : 3">
          <text-field
            v-model="quote.name"
            data-lpignore="true"
            data-testid="insured-name"
            label="Insured Name"
            :prepend-inner-icon="mdiAccount"
            :disabled="loadingQuoteData"
            :success="nameValidation.success"
            :error-messages="nameValidation.errorMessages"
          />
        </v-col>
        <v-col cols="12" :md="isDialog ? 6 : 3">
          <date-input
            v-model="quote.birthdate"
            type="text"
            inputmode="numeric"
            data-testid="insured-birthdate"
            label="Insured Birthdate"
            :disabled="loadingQuoteData"
            :prepend-inner-icon="mdiCalendar"
            :success="birthdateValidation.success"
            :error-messages="birthdateValidation.errorMessages"
          />
        </v-col>
        <v-col cols="12" :md="isDialog ? 6 : 3">
          <select-field
            v-model="quote.gender"
            label="Insured Gender"
            placeholder="Select Gender"
            data-testid="insured-gender"
            :disabled="loadingQuoteData"
            :prepend-inner-icon="mdiGenderMaleFemale"
            :success="genderValidation.success"
            :error-messages="genderValidation.errorMessages"
            :items="GENDERS"
          />
        </v-col>
        <v-col cols="12" :md="isDialog ? 6 : 3">
          <select-field
            v-model="quote.state"
            label="Insured State"
            placeholder="Select State"
            data-testid="insured-state"
            :items="STATES"
            :disabled="loadingQuoteData"
            :prepend-inner-icon="mdiSignRealEstate"
            :success="stateValidation.success"
            :error-messages="stateValidation.errorMessages"
          />
        </v-col>
      </v-row>
      <v-divider class="mx-n6 mb-3" />

      <!-- Impairments -->
      <h2 class="text-h6 mb-3">Impairments</h2>
      <v-row dense>
        <impairment-item
          v-for="(impairment, index) in quote.impairments"
          :key="impairment.uuid"
          v-model="quote.impairments[index]"
          basic
          style="width: 100%"
          :data-testid="`impairment-${index}`"
          :impairment="impairment"
          @delete-impairment="deleteImpairment(index)"
        />
      </v-row>
      <app-button
        variant="elevated"
        color="accent"
        class="text-none"
        data-testid="add-impairment"
        @click="addImpairment"
      >
        <v-icon :icon="mdiPlus" /> Add Impairment
      </app-button>
      <v-divider class="mx-n6 my-3" />

      <!-- Underwriting -->
      <h2 class="text-h6 mb-3">Underwriting Information</h2>
      <v-row dense>
        <v-col cols="12" md="6">
          <select-field
            v-model="quote.height"
            placeholder="Select Height"
            label="Insured Height"
            clearable
            data-testid="insured-height"
            :disabled="loadingQuoteData"
            :prepend-inner-icon="mdiHumanMaleHeight"
            :items="HEIGHTS"
            :success="Boolean(quote.height)"
          />
        </v-col>
        <v-col cols="12" md="6">
          <text-field
            v-model="quote.weight"
            data-lpignore="true"
            type="text"
            inputmode="numeric"
            clearable
            label="Weight"
            data-testid="insured-weight"
            :disabled="loadingQuoteData"
            :prepend-inner-icon="mdiScale"
            :success="Boolean(quote.weight)"
          />
        </v-col>
        <v-col cols="12">
          <select-field
            v-model="quote.usage"
            :prepend-inner-icon="mdiSmoking"
            clearable
            placeholder="Select Tobacco Usage"
            label="Insured Tobacco Usage"
            data-testid="insured-usage"
            :success="Boolean(quote.usage)"
            :items="SMOKING_USAGES"
          />
        </v-col>
        <v-col v-if="quote.usage === 'Yes'" cols="12">
          <textarea-field
            v-model="quote.usageDetails"
            :prepend-inner-icon="mdiFileDocumentEdit"
            rows="2"
            auto-grow
            label="Tobacco Usage Details"
            data-testid="insured-usage-details"
            maxlength="255"
            :success="Boolean(quote.usageDetails)"
          />
        </v-col>
        <v-col cols="12">
          <textarea-field
            v-model="quote.medicalHistory"
            :prepend-inner-icon="mdiBookOpenVariant"
            label="Family Medical History"
            data-testid="insured-medical-history"
            clearable
            :success="Boolean(quote.medicalHistory)"
          />
        </v-col>
      </v-row>
      <v-divider class="mx-n6 mb-3" />

      <!-- Coverage Type -->
      <h2 class="text-h6 mb-3">Coverage Type</h2>
      <v-row dense>
        <v-col cols="12" md="4">
          <select-field
            v-model="quote.coverageType"
            :prepend-inner-icon="mdiCube"
            placeholder="Select Coverage Type"
            label="Coverage Type"
            data-testid="coverage-type"
            :items="IMPAIRED_RISK_QUOTE_COVERAGE_TYPES"
            :success="coverageTypeValidation.success"
            :error-messages="coverageTypeValidation.errorMessages"
          />
        </v-col>
        <v-col cols="12" md="4">
          <currency-input
            v-model="quote.faceAmount"
            :prepend-inner-icon="mdiCurrencyUsd"
            label="Death Benefit"
            data-testid="death-benefit"
            :decimal-length="0"
            :success="faceAmountValidation.success"
            :error-messages="faceAmountValidation.errorMessages"
          />
        </v-col>
        <v-col cols="12" md="4">
          <div class="checkbox-width">
            <checkbox-field
              v-model="quote.ltc"
              :prepend-inner-icon="mdiBed"
              label="LTC/Chronic Rider"
              data-testid="ltc-chronic-rider"
              :success="quote.ltc"
            />
          </div>
        </v-col>
      </v-row>
      <v-divider class="mx-n6 mb-3" />

      <!-- Carriers -->
      <h2 class="text-h6 mb-3">
        <v-row class="ma-0" align="center">
          Carriers
          <v-icon
            v-if="carrierValidation.errorMessages.length"
            color="error"
            :icon="mdiAlertCircleOutline"
          />
          <v-spacer />
          <app-button
            v-if="hasQuoteAndApplyCarriers"
            class="text-none"
            color="accent"
            data-testid="informal-select-qna-carriers"
            :prepend-icon="quoteAndApplyIcon"
            text="Enable All Quote & Apply Carriers"
            @click="selectAllQuoteAndApplyCarriers"
          />
          <app-button
            v-else-if="carriers.length"
            class="text-none"
            data-testid="informal-select-qna-carriers-disabled"
            disabled
            :prepend-icon="quoteAndApplyIcon"
            text="No Available Quote & Apply Carriers"
          />
        </v-row>
      </h2>
      <v-row dense>
        <v-col v-if="loadingCarriers" cols="12">
          <v-row class="ma-0" align="center">
            <v-progress-circular indeterminate width="3" class="mr-3" />
            Finding Potential Carriers
          </v-row>
        </v-col>
        <v-col v-else-if="carriers.length" cols="12">
          <v-chip-group
            v-model="quote.carriers"
            column
            multiple
            selected-class="bg-primary"
          >
            <v-chip
              v-for="carrier in carriers"
              :key="carrier.name"
              :value="carrier.id"
              :text="carrier.name"
              :data-testid="`carrier-${carrier.name}`"
            />
          </v-chip-group>
        </v-col>
        <v-col v-else cols="12"> No carriers available. </v-col>
      </v-row>
    </v-card-text>
    <v-divider />

    <!-- Create -->
    <v-card-actions>
      <app-button
        v-if="isDialog"
        class="text-none"
        variant="outlined"
        data-testid="create-quote"
        @click="dialog.closeDialog()"
      >
        Cancel
      </app-button>
      <app-button
        class="text-none"
        color="primary"
        data-testid="create-quote"
        :loading="creatingQuote"
        @click="createQuote"
      >
        <v-icon :icon="mdiPlus" />
        Create Impaired Risk Quote
      </app-button>
    </v-card-actions>
  </v-card>
</template>

<script setup>
import CurrencyInput from "@/components/shared/CurrencyInput.vue";
import AgentSearch from "@/components/shared/AgentSearch.vue";
import ImpairmentItem from "@/components/impaired-risk-quote/ImpairmentItem.vue";
import DateInput from "@/components/shared/DateInput.vue";

import { STATES } from "@/data/states";
import { GENDERS } from "@/data/genders";
import { HEIGHTS } from "@/data/heights";

import { SMOKING_USAGES } from "@/models/SmokingType";
import {
  IMPAIRED_RISK_QUOTE_COVERAGE_TYPES,
  NewImpairedRiskQuote,
  NewImpairedRiskQuoteImpairment,
  setRequestFromNewImpairedRiskQuote,
  setNewImpairedRiskQuoteFromImpairedRiskQuote
} from "@/models/ImpairedRiskQuote";

import { computedValidation, parseErrorMessage } from "@/util/helpers";

import {
  getQuickQuoteCarriers,
  quickQuoteCreate
} from "@/api/quick-quotes.service";

import { useUserStore } from "@/stores/user";
import { useSnackbarStore } from "@/stores/snackbar";
import { useHead } from "@unhead/vue";
import { ref, watch } from "vue";
import useVuelidate from "@vuelidate/core";
import { useRouter } from "vue-router";
import { isValid, parse } from "@/util/date-util";
import {
  mdiAccount,
  mdiCalendar,
  mdiGenderMaleFemale,
  mdiSignRealEstate,
  mdiPlus,
  mdiHumanMaleHeight,
  mdiScale,
  mdiSmoking,
  mdiFileDocumentEdit,
  mdiBookOpenVariant,
  mdiCube,
  mdiCurrencyUsd,
  mdiBed,
  mdiAlertCircleOutline
} from "@mdi/js";
import {
  fillWithCaseData,
  fillWithEappData,
  fillWithQuoteData
} from "@/api/quick-quotes.service";
import { useDialogStore } from "@/stores/dialog";

useHead({ title: "Create Impaired Risk Quote" });

const props = defineProps({
  caseId: {
    type: Number,
    optional: true,
    default: null
  },
  quoteId: {
    type: Number,
    optional: true,
    default: null
  },
  eappId: {
    type: Number,
    optional: true,
    default: null
  },
  isDialog: Boolean
});

const dialog = useDialogStore();
const user = useUserStore();
const snackbar = useSnackbarStore();
const vueRouter = useRouter();

const quote = ref(NewImpairedRiskQuote());
const carriers = ref([]);
const creatingQuote = ref(false);
const loadingCarriers = ref(false);
const hasQuoteAndApplyCarriers = ref(false);
const loadingQuoteData = ref(false);

const v$ = useVuelidate(
  {
    quote: {
      coverageType: { required: Boolean },
      faceAmount: { required: Boolean },
      advisor: { required: Boolean },
      name: { required: Boolean },
      birthdate: {
        required: Boolean,
        reasonableDate: v => {
          try {
            const date = parse(v, "yyyy-MM-dd");
            if (!isValid(date)) return false;
            return date.getFullYear() > 1900;
          } catch (e) {
            return false;
          }
        }
      },
      gender: { required: Boolean },
      state: { required: Boolean },
      impairments: { required: v => v.length > 0 },
      carriers: { required: v => v.length > 0 }
    }
  },
  { quote },
  { $autoDirty: true, $scope: "impaired-risk-quote" }
);

const advisorValidation = computedValidation(v$.value.quote.advisor, {
  required: "Required"
});

const nameValidation = computedValidation(v$.value.quote.name, {
  required: "Required"
});

const birthdateValidation = computedValidation(v$.value.quote.birthdate, {
  required: "Required",
  reasonableDate: "Please enter a birthdate after 1900"
});

const genderValidation = computedValidation(v$.value.quote.gender, {
  required: "Required"
});
const stateValidation = computedValidation(v$.value.quote.state, {
  required: "Required"
});

const coverageTypeValidation = computedValidation(v$.value.quote.coverageType, {
  required: "Required"
});

const faceAmountValidation = computedValidation(v$.value.quote.faceAmount, {
  required: "Required"
});

const carrierValidation = computedValidation(v$.value.quote.carriers, {
  required: "Required"
});

watch(
  [
    () => quote.value.faceAmount,
    () => quote.value.coverageType,
    () => quote.value.state,
    () => quote.value.birthdate
  ],
  debounceAndGetAcceptingCarriers
);

let timer;
function debounceAndGetAcceptingCarriers() {
  if (
    !quote.value.faceAmount ||
    !quote.value.coverageType ||
    !quote.value.state ||
    !quote.value.birthdate
  )
    return;

  loadingCarriers.value = true;
  if (timer) clearTimeout(timer);
  timer = setTimeout(() => {
    getAcceptingCarriers();
  }, 1000);
}

async function getAcceptingCarriers() {
  try {
    const quickQuoteCarriers = await getQuickQuoteCarriers({
      faceAmount: quote.value.faceAmount,
      typeOfInsurance: quote.value.coverageType,
      state: quote.value.state,
      birthdate: quote.value.birthdate
    });

    carriers.value.splice(0, carriers.value.length);
    carriers.value.push(...quickQuoteCarriers);

    hasQuoteAndApplyCarriers.value = carriers.value.some(
      c => c.strifeApproved === true
    );

    if (quote.value.carriers.length) {
      quote.value.carriers = quote.value.carriers.filter(carrierId =>
        carriers.value.some(({ id }) => id === carrierId)
      );
    }
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    loadingCarriers.value = false;
  }
}

function selectAllQuoteAndApplyCarriers() {
  const qnaCarriers = carriers.value.filter(
    ({ strifeApproved }) => strifeApproved
  );

  quote.value.carriers.splice(0, quote.value.carriers.length);
  quote.value.carriers.push(...qnaCarriers.map(({ id }) => id));
}

function addImpairment() {
  quote.value.impairments.push(NewImpairedRiskQuoteImpairment());
}

function deleteImpairment(index) {
  quote.value.impairments.splice(index, 1);
}

async function createQuote() {
  const isValid = await v$.value.$validate();
  if (!isValid) {
    let message = "Invalid Fields Detected";
    if (v$.value.quote.carriers.$invalid) {
      message += ", please select at least one Carrier";
    }
    snackbar.showErrorSnackbar({ message });
    return;
  }

  creatingQuote.value = true;
  try {
    const id = await quickQuoteCreate(
      setRequestFromNewImpairedRiskQuote(quote.value)
    );
    vueRouter.push({ name: "ImpairedRiskQuoteView", params: { id } });
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e), timeout: -1 });
  } finally {
    creatingQuote.value = false;
  }
}

async function initializeQuote() {
  quote.value.creator = user.loginable;
  let loginable = user.loginable;
  if (user.loginable.type === "Agency") loginable = user.loginable.signer;
  quote.value.advisor = loginable;

  if (props.caseId) await setFromCase(props.caseId);
  else if (props.quoteId) await setFromQuote(props.quoteId);
  else if (props.eappId) await setFromEapp(props.eappId);

  addImpairment();
}

async function setFromEapp(eappId) {
  try {
    loadingQuoteData.value = true;
    const prefilledQuote = await fillWithEappData(eappId);
    quote.value = setNewImpairedRiskQuoteFromImpairedRiskQuote(prefilledQuote);
    quote.value.eappId = eappId;
    v$.value.$reset();
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    loadingQuoteData.value = false;
  }
}

async function setFromQuote(quoteId) {
  try {
    loadingQuoteData.value = true;
    const prefilledQuote = await fillWithQuoteData(quoteId);
    quote.value = setNewImpairedRiskQuoteFromImpairedRiskQuote(prefilledQuote);
    quote.value.quoteId = quoteId;
    v$.value.$reset();
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    loadingQuoteData.value = false;
  }
}

async function setFromCase(caseId) {
  try {
    loadingQuoteData.value = true;
    const prefilledQuote = await fillWithCaseData(caseId);
    quote.value = setNewImpairedRiskQuoteFromImpairedRiskQuote(prefilledQuote);
    quote.value.caseId = caseId;
    v$.value.$reset();
  } catch (e) {
    snackbar.showErrorSnackbar({ message: parseErrorMessage(e) });
  } finally {
    loadingQuoteData.value = false;
  }
}

initializeQuote();
</script>
