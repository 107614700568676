<template>
  <app-button-with-menu
    v-if="quotes.length"
    :block="smAndDown"
    data-testid="view-quote-button"
    :prepend-icon="mdiClipboardAccount"
    :to="{
      name: 'QuoteView',
      params: { id: quotes[quotes.length - 1].id }
    }"
  >
    <template #button> View Quote </template>
    <template #menu>
      <v-list>
        <v-list-item
          v-for="(quote, index) in quotes"
          :key="quote.id"
          :to="{ name: 'QuoteView', params: { id: quote.id } }"
          data-testid="view-quote-menu-item"
        >
          Quote #{{ index + 1 }} ({{ quote.status }})
        </v-list-item>
        <v-list-item
          color="accent"
          data-testid="view-quote-menu-create"
          @click="emit('create')"
        >
          Create Quote
        </v-list-item>
      </v-list>
    </template>
  </app-button-with-menu>
  <app-button
    v-else
    variant="outlined"
    color="primary"
    class="text-none rounded-pill"
    data-testid="request-quote-button"
    text="Request Quote"
    :block="smAndDown"
    :prepend-icon="mdiClipboardAccount"
    @click="emit('create')"
  />
</template>

<script setup>
import AppButtonWithMenu from "@/components/shared/AppButtonWithMenu.vue";
import { mdiClipboardAccount } from "@mdi/js";

defineProps({
  block: Boolean,
  quotes: {
    type: Array,
    required: true
  }
});
const emit = defineEmits(["create"]);
</script>
