import { getHttpClient } from "@/http-client";
import { setInformalOfferFromRequest } from "@/models/InformalOffer";
import {
  setImpairedRiskQuoteFromRequest,
  setImpairedRiskQuoteRoomAccessFromRequest
} from "@/models/ImpairedRiskQuote";

const baseUrl = "api/boss/quick_quotes";
let cancelToken = null;

function QuickQuoteTableItem(model = {}) {
  return {
    id: model?.id,
    insuredName: model?.insuredName,
    advisorName: model?.advisorName,
    createdAt: model?.createdAt,
    offers: model?.offers,
    impairment: model?.impairment,
    view: model?.view,
    status: model?.status,
    requests: model?.requests
  };
}

function setQuickQuoteTableItemFromRequest(req = {}) {
  const item = QuickQuoteTableItem();

  item.id = req.id;
  item.insuredName = req.insured;
  item.advisorName = req.advisor.name;
  item.createdAt = req.created_at;
  item.offers = req.offers;
  item.impairment = req.impairment;
  item.view = req.view;
  item.status = req.status;
  item.requests = req.requests;

  return item;
}
export async function getQuickQuotes(params) {
  if (cancelToken !== null) {
    cancelToken.cancel("Operation canceled due to new request.");
  }
  cancelToken = getHttpClient().CancelToken.source();
  const response = await getHttpClient().get(baseUrl, {
    params,
    cancelToken: cancelToken.token
  });

  if (!response?.data) return;

  return {
    items: response.data.quick_quotes.map(setQuickQuoteTableItemFromRequest),
    meta: response.data.meta
  };
}

function QuickQuoteCarrier(model = {}) {
  return {
    name: model?.name,
    id: model?.id,
    strifeApproved: model?.strifeApproved
  };
}

function setQuickQuoteCarrierFromRequest(req = {}) {
  const carrier = QuickQuoteCarrier();

  carrier.name = req?.name;
  carrier.id = req?.id;
  carrier.strifeApproved = req?.quote_and_apply_carrier;

  return carrier;
}

export async function getQuickQuoteCarriers({
  faceAmount,
  typeOfInsurance,
  state,
  birthdate
}) {
  const params = new URLSearchParams();
  params.append("face_amount", faceAmount);
  params.append("type_of_insurance", typeOfInsurance);
  params.append("state", state);
  params.append("birthdate", birthdate);

  const { data } = await getHttpClient().get(`${baseUrl}/carriers`, { params });

  const carriers = data.carriers;
  carriers.sort((a, b) => a.name.localeCompare(b.name));

  return carriers.map(setQuickQuoteCarrierFromRequest);
}

export async function quickQuoteCreate(body) {
  const { data } = await getHttpClient().post(baseUrl, body);

  return data.quick_quote.id;
}

export async function getQuickQuote(id) {
  const { data } = await getHttpClient().get(`${baseUrl}/${id}`);
  return setImpairedRiskQuoteFromRequest(data.quick_quote);
}

export async function getOffer(quoteId, informalOfferId) {
  const { data } = await getHttpClient().get(
    `${baseUrl}/${quoteId}/informal_offers/${informalOfferId}/notes`
  );

  const informal = setInformalOfferFromRequest(data.informal_offer);
  return informal;
}

export function removeQuickQuotesAccess(quoteId, accessId) {
  return getHttpClient().delete(`${baseUrl}/${quoteId}/accesses/${accessId}`);
}

export function updateQuickQuoteAccess(quoteId, accessId, body) {
  return getHttpClient().put(
    `${baseUrl}/${quoteId}/accesses/${accessId}`,
    body
  );
}

export async function addQuickQuoteAccess(quoteId, quick_quote) {
  const { data } = await getHttpClient().post(
    `${baseUrl}/${quoteId}/accesses`,
    { quick_quote }
  );

  return setImpairedRiskQuoteRoomAccessFromRequest(data);
}

export function updateQuickQuote(quoteId, quick_quote) {
  return getHttpClient().put(`${baseUrl}/${quoteId}`, { quick_quote });
}

export async function fillWithCaseData(caseId) {
  const { data } = await getHttpClient().get(`${baseUrl}/from-case/${caseId}`);
  return setImpairedRiskQuoteFromRequest(data.quick_quote);
}

export async function fillWithEappData(eappId) {
  const { data } = await getHttpClient().get(`${baseUrl}/from-eapp/${eappId}`);
  return setImpairedRiskQuoteFromRequest(data.quick_quote);
}

export async function fillWithQuoteData(quoteId) {
  const { data } = await getHttpClient().get(
    `${baseUrl}/from-quote/${quoteId}`
  );
  return setImpairedRiskQuoteFromRequest(data.quick_quote);
}
