import { setMarketingManagerTableItemFromRequest } from "@/models/MarketingManagerTableItem";
import { getHttpClient, getAuthHttpClient } from "@/http-client";
import { getRouterLink } from "@/util/helpers.js";
const baseUrl = "api/boss";

export const passwordChange = (currentPassword, newPassword) => {
  const body = {
    current_password: currentPassword,
    password: newPassword
  };
  return getHttpClient().put(`${baseUrl}/me`, body);
};

export const getAllPricingPlans = () => {
  return new Promise((resolve, reject) => {
    getHttpClient()
      .get(`${baseUrl}/pricing_plans`)
      .then(res => {
        resolve(res?.data);
      })
      .catch(reject);
  });
};

export const getHolidays = () => {
  return new Promise((res, rej) => {
    getHttpClient()
      .get(`${baseUrl}/business_time`)
      .then(response => {
        res(
          response?.data?.map(val => ({
            date: val.date,
            title: val.holiday,
            isBusinessDay: val.business_day
          }))
        );
      })
      .catch(rej);
  });
};

export const validateEmail = async options => {
  try {
    const response = await getHttpClient().post(
      `${baseUrl}/validations/email_verify`,
      options
    );

    return response?.data?.valid === true;
  } catch (e) {
    return false;
  }
};

function GlobalSearchItem(model = {}) {
  return {
    title: model?.text,
    type: model?.type,
    id: model?.id
  };
}

const typeMap = {
  agents: "Agent",
  agencies: "Agency",
  cases: "Case",
  carriers: "Carrier",
  appointments: "Appointment",
  products: "Product",
  personnel: "Personnel",
  ElectronicApplication: "ElectronicApplication",
  QuickQuote: "QuickQuote"
};

function setGlobalSearchItemFromRequest(raw) {
  const item = GlobalSearchItem();

  item.text = raw.text;
  item.id = raw.value.id;
  item.type = typeMap[raw.value.app_link];
  item.routerLink = getRouterLink(item.type, item.id);

  return item;
}

let getAdvisorsCancelToken = null;
export async function searchGlobally(q) {
  if (getAdvisorsCancelToken !== null) {
    getAdvisorsCancelToken.cancel("Operation canceled due to new request.");
  }
  getAdvisorsCancelToken = getHttpClient().CancelToken.source();
  const response = await getHttpClient().post(
    `${baseUrl}/search`,
    { q },
    { cancelToken: getAdvisorsCancelToken.token }
  );

  if (!response?.data) return;
  return response.data.map(setGlobalSearchItemFromRequest);
}

export async function getResource(provider, ssoAction = null) {
  const params = new URLSearchParams();
  params.append("provider", provider);
  if (ssoAction) params.append("sso_action", ssoAction);
  const { data } = await getHttpClient().get(`${baseUrl}/web_services`, {
    params
  });

  return data.link;
}

export const getRangeOfExposureLink = async () => {
  return new Promise((resolve, reject) => {
    getHttpClient()
      .post(`${baseUrl}/links/ltc-range-of-exposure`)
      .then(({ data }) => {
        resolve(data.url);
      })
      .catch(reject);
  });
};

export const getCostBenefitLink = async ({
  firstName,
  lastName,
  email,
  phone,
  state
}) => {
  return new Promise((resolve, reject) => {
    const body = {
      UserName: email,
      FirstName: firstName,
      LastName: lastName,
      Email: email,
      Phone: phone,
      State: state,
      LogoutReturnUrl: "https://app.back9ins.com"
    };
    getHttpClient()
      .post("https://www.ltcicalc.com/sso.cfm?agency=BackNine", body)
      .then(() => {
        resolve("https://www.ltcicalc.com");
      })
      .catch(reject);
  });
};

export const getMarketingManagers = async () => {
  const { data } = await getHttpClient().post("/api/boss/search/mms");
  return data.marketing_managers.map(setMarketingManagerTableItemFromRequest);
};

export const getEfts = async (id, type) => {
  const params = new URLSearchParams();
  if (type === "agent") {
    params.append("agent_id", id);
  } else if (type === "agency") {
    params.append("agency_id", id);
  } else {
    throw "Invalid Type";
  }
  const { data } = await getHttpClient().get("/api/boss/efts", {
    params
  });

  return data.map(({ id, paying_to }) => ({ title: paying_to, value: id }));
};

export const getMe = async () => {
  const { data } = await getAuthHttpClient().get("/api/boss/me");
  return data;
};
