<template>
  <v-dialog
    :model-value="activeDialogProps.enabled"
    :max-width="activeDialogProps.props.width || '50rem'"
    :max-height="activeDialogProps.props.maxHeight"
    :persistent="activeDialogProps.props.persistent"
    :fullscreen="activeDialogProps.props.fullscreen"
    :style="activeDialogProps.props.style"
    :scrollable="activeDialogProps.props.scrollable"
    @click:outside="outsideClick"
  >
    <template
      v-for="(stack, index) in dialogStack"
      :key="`${stack.component.__name}-${index}`"
    >
      <component
        :is="markRaw(stack.component)"
        v-show="index === dialogStack.length - 1"
        v-bind="stack.props"
      />
    </template>
  </v-dialog>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { useDialogStore } from "@/stores/dialog";
import { computed, markRaw } from "vue";

const dialog = useDialogStore();

const { dialogStack, show } = storeToRefs(dialog);

const activeDialogProps = computed(() => {
  const length = dialogStack.value.length;
  if (!length) return { enabled: false, props: {} };
  const activeDialog = dialogStack.value[length - 1];
  return {
    enabled: true,
    props: activeDialog.props
  };
});
const dialogEnable = computed({
  get() {
    return show.value;
  },
  set(val) {
    if (val) {
      throw new Error("Do not open a dialog from the Dialog Manager");
    }
    dialog.closeDialog();
  }
});

function outsideClick() {
  if (activeDialogProps.value.props.persistent) return;
  dialogEnable.value = false;
}
</script>
