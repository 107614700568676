<template>
  <div>
    <app-button
      class="text-none button-group left-button rounded-pill"
      :class="{
        'grouped-centered-block-button': smAndDown
      }"
      :color="color"
      :data-testid="dataTestid"
      variant="outlined"
      v-bind="$attrs"
    >
      <slot name="button" />
    </app-button>
    <v-menu offset-y left>
      <template #activator="{ props: templateProps }">
        <app-button
          :color="color"
          :data-testid="`${dataTestid}-menu`"
          class="px-1 button-group right-button rounded-pill"
          variant="outlined"
          :icon="mdiMenuDown"
          density="comfortable"
          v-bind="templateProps"
        />
      </template>
      <slot name="menu" />
    </v-menu>
  </div>
</template>

<script setup>
import { mdiMenuDown } from "@mdi/js";
import { useDisplay } from "vuetify";
const { smAndDown } = useDisplay();
defineProps({
  color: {
    type: String,
    required: false,
    default: "primary"
  },
  dataTestid: {
    type: String,
    required: false,
    default: null
  }
});
</script>
