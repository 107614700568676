<template>
  <v-data-table-server
    v-model:sort-by="table.options.value.sortBy"
    v-model:items-per-page="table.options.value.itemsPerPage"
    v-model:page="table.options.value.page"
    must-sort
    data-testid="advisor-profile-impaired-risk-quotes-table"
    :mobile="null"
    mobile-breakpoint="sm"
    :headers="table.tableHeaders.value"
    :items="table.mappedItems.value"
    :items-length="table.itemsLength.value"
    :loading="table.loading.value"
    :footer-props="table.footerProps.value"
    :items-per-page-options="table.itemsPerPageOptions"
    @update:options="getData"
  >
    <template #bottom>
      <v-row class="ma-0 pa-3">
        <v-spacer />
        <app-button
          data-testid="advisor-profile-impaired-risk-quotes-table-view-all"
          color="primary"
          class="text-none"
          variant="text"
          :prepend-icon="mdiOpenInApp"
          :text="`View ${name}'s Impaired Risk Quotes`"
          @click="viewAll"
        />
      </v-row>
    </template>

    <template #[`item.createdAt`]="{ item }">
      <timestamp-formatter :model-value="item.createdAt" />
    </template>
    <template #[`item.insuredName`]="{ item }">
      <router-link
        :to="{
          name: 'ImpairedRiskQuoteView',
          params: { id: item.additional.id }
        }"
        class="text-none"
      >
        {{ item.insuredName }}
      </router-link>
    </template>
    <template #[`item.offers`]="{ item }">
      {{ item.additional.offers }} of {{ item.additional.requests }}
    </template>
  </v-data-table-server>
</template>

<script setup>
import TimestampFormatter from "@/components/shared/formatters/TimestampFormatter.vue";

import TableHeader from "@/classes/data-table/TableHeader";
import { TableOptions } from "@/classes/data-table/TableOptions";
import { parseErrorMessage } from "@/util/helpers";

import { mdiOpenInApp } from "@mdi/js";
import { getQuickQuotes } from "@/api/quick-quotes.service";
import { useUserStore } from "@/stores/user";
import { useSnackbarStore } from "@/stores/snackbar";
import { useTableStore } from "@/stores/table";
import { useAdvisorProfileView } from "@/stores/advisor-profile-view";
import { storeToRefs } from "pinia";
import { useTable } from "@/composables/table.composable";
import { useRouter } from "vue-router";

const snackbar = useSnackbarStore();
const user = useUserStore();
const tableStore = useTableStore();
const router = useRouter();
const { id, type, tableFilterData, name } = storeToRefs(
  useAdvisorProfileView()
);

const table = useTable({
  headers: [
    new TableHeader({
      text: "Insured Name",
      value: "insuredName",
      map: "insuredName"
    }),
    new TableHeader({
      text: "Created",
      value: "createdAt",
      map: "createdAt",
      sortFilterMap: "quick_quotes.created_at",
      ...TableHeader.IS_SORTABLE
    }),
    new TableHeader({
      text: "# Offers",
      value: "offers",
      map: "offers"
    }),
    new TableHeader({
      text: "Status",
      value: "status",
      map: "status"
    })
  ],
  getData: getQuickQuotes,
  options: TableOptions({ sortBy: [{ key: "createdAt", order: "desc" }] })
});

defineExpose({ getData });

async function getData() {
  const additionalFilter = {
    advisor_id: id.value,
    advisor_type: type.value,
    view: "all"
  };

  try {
    await table.getData(additionalFilter);
  } catch (e) {
    snackbar.showErrorSnackbar({
      message: parseErrorMessage(e)
    });
  }
}

function viewAll() {
  tableStore.impairedRiskQuotesTable.filter = {
    advisor: tableFilterData.value
  };
  if (user.isGroupTwoPlus) {
    tableStore.impairedRiskQuotesTable.filter.view = "all";
  }
  router.push({ name: "Quotes", query: { page: "impaired-risk-quotes" } });
}
</script>
